import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { FormControl, FormControlName, FormGroup, Validators } from '@angular/forms';
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";

import {HPLTrimService } from '../../services/trim/trim.service';
import { Trim } from '../../services/trim/trim';
import { Allocation } from '../../services/trim/allocation/allocation';
import { PackageInfo } from '../../services/package.info';

import { HelperService } from '../../../services/helper.service';
import { GtmService } from '../../../services/gtm-tracking.service';
import { AdobeTMService } from '../../../services/adobe-tm.service';


@Component({
  selector: 'hpl-search-filters',
  templateUrl: './search-filters.component.html',
  styleUrls: ['./search-filters.component.less']
})
export class HPLSearchFiltersComponent implements OnInit {

  @ViewChild('searchForm') searchForm: ElementRef;
  @Input() selectedModel: string;
  @Input() zipcode: string;
  @Input() availableCharger: boolean;
  @Input() availableDurango: boolean;

  // trim selection
  @Output() newTrimSelected = new EventEmitter<String>();
  @Output() newModelSelected = new EventEmitter<String>();
  @Output() newAreaSearch = new EventEmitter<String>();
  @Output() configIdSelected = new EventEmitter<Number>();

  activeTrims: Trim[] = [];
  activeSpecialEditions: Trim[] = [];
  allocationsByTrim: Allocation[] = [];
  mySelectedTrim: string = '';
  zipcodeArea: string = '';
  packages = PackageInfo;
  packageInfoModalRef: BsModalRef;
  activePackage: any;
  durangoGroups = null;

  searchFilterForm = new FormGroup({
    zipcode: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$")]),
    zipcodeRadius: new FormControl(this.zipcodeArea, Validators.required),
    model: new FormControl(''),
    trimName: new FormControl('')
  })

  onModelUpdate(event) {
    // this emits the string value of the selected model

    // clears the trim radio selects when a new model is selected
    this.searchFilterForm.controls['trimName'].reset();
    this.newModelSelected.emit(this.searchFilterForm.value);
    this.setTrimsModels(event.target.value);

    this.configIdSelected.emit(0);

    setTimeout(() => {
      const currentModel = this.selectedModel === 'Charger Daytona 2-Door' ? 'daytona' : 'durango'

      // Tracking for model update
      this.gtmService.pushEvent({
        'event': 'hpl.model_select.update',
        'hpl.model_select.model': currentModel
      });

      this.adobeTMService.pushToDataLayer({
        "event":"interaction-click",
        "interaction": {
          "site":"dodgegarage",
          "type":"func",
          "page":"shopping-tools:horsepower-locator",
          "location":"filtering-options:model",
          "description": currentModel
        },
        "vehicle": {
          "make": "dodge",
          "model": currentModel,
          "year": "2024"
        }
      });
    }, 500)
  }

  setTrimsModels(model: string = this.selectedModel) {
    this.activeTrims = this.trimService.getTrimsByModel(model);
    this.activeSpecialEditions = this.trimService.getSpecialEditionsByModel(model);
    console.log('---- Testing Durango Trims -----');
    console.log(this.activeSpecialEditions);
    console.log(this.selectedModel)
    console.log(model)

    if (model === 'Durango') {
      this.durangoGroups = new Set(this.activeSpecialEditions.map(item => item.group));
    }
  }

  onSearchZipcodeUpdate(event) {
    this.newAreaSearch.emit(this.searchFilterForm.value);

    // Tracking for zipcode update
    this.gtmService.pushEvent({
      'event': 'hpl.zipcode_search_sidebar.update',
      'hpl.zipcode_search_sidebar.zip': this.searchFilterForm.controls['zipcode'].value
    });

    this.adobeTMService.pushToDataLayer({
      "event":"interaction-click",
      "interaction": {
        "site":"dodgegarage",
        "type":"func",
        "page":"shopping-tools:horsepower-locator",
        "location":"filtering-options:zipcode",
        "description":"update-zipcode"
      },
      "user": {
        "zipCode": this.searchFilterForm.controls['zipcode'].value
      }
    });
  }

  onSelectTrim(trimName: string, trimLabel: string, configId: number) {
    this.mySelectedTrim = trimName;
    this.newTrimSelected.emit(this.searchFilterForm.value);
    this.configIdSelected.emit(configId);

    // Tracking for model update
    this.gtmService.pushEvent({
      'event': 'hpl.trim_select.update',
      'hpl.trim_select.trim': trimLabel
    });
  }

  // redundant but functional for reactive radius changing
  onRadiusUpdate() {
    this.newAreaSearch.emit(this.searchFilterForm.value);

    let activeRadius: string;

    if (this.searchFilterForm.controls['zipcodeRadius'].value === 'all') {
      activeRadius = 'nationwide'
    }else {
      activeRadius = `${this.searchFilterForm.controls['zipcodeRadius'].value}-miles`
    }

    // Tracking for radius update
    this.gtmService.pushEvent({
      'event': 'hpl.radius_search_sidebar.update',
      'hpl.radius_search_sidebar.zip': this.searchFilterForm.controls['zipcode'].value,
      'hpl.radius_search_sidebar.radius': this.searchFilterForm.controls['zipcodeRadius'].value
    });

    this.adobeTMService.pushToDataLayer({
      "event":"interaction-click",
      "interaction": {
        "site":"dodgegarage",
        "type":"func",
        "page":"shopping-tools:horsepower-locator",
        "location":"filtering-options:search-within",
        "description": activeRadius
      }
    });
  }

  constructor(
    private trimService: HPLTrimService,
    public helperService: HelperService,
    private modalService: BsModalService,
    private adobeTMService: AdobeTMService,
    private gtmService: GtmService
  ) { }

  ngOnInit(): void {
    this.setTrimsModels();
    //set the initial values for the FormGroup here that were passed in via the parent component
    this.searchFilterForm.patchValue({
      zipcodeRadius: 'all',
      zipcode: this.zipcode,
      model: this.selectedModel
    })
  }

  trackTrimSelection(trim: Trim): void {
    const trimFormatted = (trim.label).toLowerCase().replace(/<br>/gi, '').replace(/[^a-z0-9\s]/g, '').replace(/\s+/g, '-').trim();
    const data = {
      "event":"interaction-click",
      "interaction": {
        "site":"dodgegarage",
        "type":"func",
        "page":"shopping-tools:horsepower-locator",
        "location":"filtering-options:special-editions",
        "description": "select-" + trimFormatted
      }
    };
    this.adobeTMService.pushToDataLayer(data);
  }

  trackTrimSelectionBasic(trim: Trim): void {
    const trimFormatted = (trim.group).toLowerCase().replace(/ /g, '-').replace(/[^a-z0-9-]/g, '');
    const data = {
      "event":"interaction-click",
      "interaction": {
        "site":"dodgegarage",
        "type":"func",
        "page":"shopping-tools:horsepower-locator",
        "location":"filtering-options:trims",
        "description": "select-" + trimFormatted
      }
    };
    this.adobeTMService.pushToDataLayer(data);
  }

  openPackageInfoModal(modalName: TemplateRef<any>, event, currentPackage: any, modelID: number) {
    event.preventDefault();

    // Fire adobe tracking
    this.adobeTMService.pushToDataLayer({
      "event":"interaction-click",
      "interaction": {
        "site":"dodgegarage",
        "type":"func",
        "page":"shopping-tools:horsepower-locator",
        "location":"filtering-options:package-info",
        "description": `${ modelID === 0 ? 'Stage 1' : 'Stage 2'} ${currentPackage.name}`
      }
    });
    this.gtmService.pushEvent({
      'event': 'hpl.package_info_modal.open',
      'hpl.package_info_modal.model': modelID === 0 ? 'Stage 1' : 'Stage 2',
      'hpl.package_info_modal.package': currentPackage.name
    });

    this.activePackage = currentPackage;

    // Show the new modal
    this.packageInfoModalRef = this.modalService.show(modalName, Object.assign({}, { class: 'modal-package-info' }));
  }

}

