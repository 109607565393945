<div style="position: relative;">
    <section id="horsepower-locator-tile">
        <div class="hero-container fade-in">
            <div class="horsepower-logo">
                <img src="/assets/images/hpl-logo-white-base.webp" alt="Dodge Horsepower Locator - Logo" class="img-responsive" width="768" height="413">


            </div>
            <div class="horsepower-content">
                <p id="dont-say-warning-text" class="text-center text-white text-uppercase">
                      <span class="combo">
                        CHARGER DAYTONA 2-DOOR
                      </span>
                    <span>+</span>
                    <span>DURANGO SPECIAL EDITIONS</span>
                </p>
                <a routerLink="/horsepower-locator" id="hpl-breakout-link" class="btn btn-primary" (click)="sendGTMEvent()">Find your ride</a>
            </div>
            <div class="incentives-copy text-center">
                <p class="mb-0">
                    GET 0% APR FOR 72 MONTHS 
                    <span class="v-adjust" (click)="openTooltipModal(1)">1</span>
                </p>
                <p class="mb-0">+</p>
                <p>$3,000 BONUS CASH ALLOWANCE <span class="v-adjust" (click)="openTooltipModal(2)">2</span></p>
                <p>
                    TOWARD THE PURCHASE OF A 2024 DODGE CHARGER DAYTONA
                </p>
            </div>
        </div>
    </section>
</div>

<ng-template #tooltipModal>
    <div class="modal-container modal-container-lg">
        <div class="modal-header">
            <button class="modal-close" (click)="tooltipModalRef.hide()"><i class="fa fa-close"><span class="sr-only">close</span></i></button>
        </div>
        <div class="modal-body">
            <p class="fs-legal" *ngIf="activeTooltip === 1">
                0% APR financing for 72 months equals $13.89 per month per $1,000 financed for well-qualified buyers through Stellantis Financial Services regardless of down payment. Not all buyers will qualify. Not compatible with any other incentive programs or offers. Residency restrictions apply. Contact dealer for details. Must take delivery by 04/30/2025.
            </p>
            <p class="fs-legal" *ngIf="activeTooltip === 2">
                Contact dealer for details. Must take delivery by 04/30/2025.
            </p>
        </div>
    </div>
</ng-template>
