<div id="homepage-hero-fixed" class="hero-wrapper">
  <badassador-hero></badassador-hero>
</div>

<h1 class="sr-only">Welcome to Dodgegarage!</h1>

<div class="content-wrap relative bg-white">
  <div class="site-wrapper">
    <section>
      <performance-cars-activation-banner></performance-cars-activation-banner>
      <dg-password-reset-banner *ngIf="displayPasswordResetBanner"></dg-password-reset-banner>
    </section>
  </div> 

  <div class="bg-white">
    <div class="site-wrapper-xl">
      <dg-recent-articles id="article-feature"></dg-recent-articles>

      <dg-livery-contest-tile></dg-livery-contest-tile>

      <dg-horsepower-locator-tile></dg-horsepower-locator-tile>

      <daytona-charger class="bg-black" [isInline]="true"></daytona-charger>

      <div class="section-half">
        <div class="split">
          <dg-demon-170-tile></dg-demon-170-tile>
        </div>
        <div class="split pt-0">
          <div id="dodge-history">
              <div class="content">
                  <h2 class="text-white">POWER THROUGH TIME</h2>
                  <a href="/history" class="btn btn-primary">EXPLORE THE DODGE HISTORY TIMELINE</a>
              </div>
          </div>
        </div>
      </div>

      <!-- <eight-second-club-tile></eight-second-club-tile> -->

      <div class="feature-slider" appLazyLoad (isVisible)="loadFeatureSlider()">
        <feature-slider *ngIf="featureSliderLoaded"></feature-slider>
      </div>
    </div>
  </div>

  <div class="bg-gray-light" appLazyLoad (isVisible)="loadEventSlider()">
    <div class="site-wrapper-xl">
      <events-slider *ngIf="eventsSliderLoaded"></events-slider>
    </div>
  </div>

  <div class="bg-black" appLazyLoad (isVisible)="loadMissionBanner()">
    <div class="site-wrapper-xl">
      <mission-banner *ngIf="missionBannerLoaded"></mission-banner>
    </div>
  </div>

  <div class="site-wrapper-xl">
    <div appLazyLoad (isVisible)="loadVideoSection()">
      <videos-section *ngIf="videoSectionLoaded"></videos-section>
    </div>

    <div class="section-half" appLazyLoad (isVisible)="loadPollSection()">
      <div class="split" *ngIf="isDgPollLoaded">
        <div id="dg-poll" class="poll-container relative">
          <!-- some styles were added to src/app/polls/poll/poll.component.less since poll 22 is so wordy -->
          <dg-poll [pollId]="25" *ngIf="isDgPollLoaded"></dg-poll>
        </div>
      </div>
      <div class="split" *ngIf="isDgPollLoaded">
        <a routerLink="/track-experience" class="feature-tile ga-event" data-dest="Track Experience" data-ga_category="Home" data-ga_action="Click" data-ga_label="Feature Tile">
          <img class="feature-tile-img" src="/assets/images/feature-bg-radford-racing.jpg" alt="" class="img-responsive"> 
          <span class="feature-tile-content">
            <img src="/assets/images/cta-tiles/radford-logo.png" alt="Radford Racing - Logo">
            <p>Submit your qualifying VIN to attend.</p>
          </span>
        </a>
      </div>
    </div>

    <div class="tile-grid three-up" appLazyLoad (isVisible)="loadTileSection()">
      <dg-racing-hq-cta class="cta-tile" *ngIf="tileSectionLoaded"></dg-racing-hq-cta>
      <thirteen-twenty-cta class="cta-tile" *ngIf="tileSectionLoaded"></thirteen-twenty-cta>
      <dg-drag-pak-cta class="cta-tile" *ngIf="tileSectionLoaded"></dg-drag-pak-cta>
    </div>

    <div class="section-split" appLazyLoad (isVisible)="loadSalesIncentives()">
      <div class="power-dollars relative bg-white">
        <dg-sales-incentives *ngIf="isDgSalesIncentivesLoaded"></dg-sales-incentives>
      </div>
    </div>
  </div>

  <div class="bg-gray-darkest" appLazyLoad (isVisible)="loadSocialSection()">
    <div class="site-wrapper-xl">
      <social-banner *ngIf="isSocialBannerLoaded"></social-banner>
    </div>
  </div>
</div>

<!-- User Modal -->
<ng-template #userModal>
  <div class="modal-container">
    <div class="modal-header">
      <button class="modal-close" (click)="closeUserModal()"><i class="fa fa-close"><span class="sr-only">close</span></i></button>
    </div>
    <div class="modal-body site-wrapper">
      <div class="signup-form form-1320">
        <dg-user-onboarding class="step-form" [defaultTab]="userModalDefaultTab" (userLoggedIn)="closeUserModal()"></dg-user-onboarding>
      </div>
    </div>
  </div>
</ng-template>
