<div id="livery-landing">
    <div class="site-wrapper">
        <div class="row">
            <ng-container *ngIf="!isLoading">
                <section id="livery-content" class="col-12" >
                    <div id="livery-intro" class="text-center">
                        <h2>Dodge motorsports livery shootout</h2>
                        <div *ngIf="!isLoggedIn">
                            <p class="giga">Pump the brakes! You must log in to vote.</p>
                            <p>Log in to your DodgeGarage account – or sign up – to cast your vote in the Livery Shootout.</p>
                        </div>

                        <div *ngIf="!isLoggedIn" id="livery-voting">
                            <div class="text-center">
                                <button class="btn btn-primary   " tabindex="0" (click)="userService.clearMessages();openUserModal($event)">
                                    Log in to vote
                                </button>
                            </div>
                        </div>

                        <div *ngIf="isLoggedIn && !votedSinceYesterday">
                            <p class="giga">Choose your contender.</p>
                            <p>Vote for your favorite livery design. The winner will be raced by Matt Hagan and Tony Stewart at this year's US Nationals, Aug 27 - Sept. 1.</p>
                        </div>

                        <div *ngIf="isLoggedIn && votedSinceYesterday">
                            <p class="giga"><i class="red-check fa fa-check"></i> Nice Choice.</p>
                            <p>Your vote has been counted. Return and vote again – once each day – until voting closes on June 2, 2025.</p>
                        </div>
                    </div>

                    <div id="livery-options" [ngClass]="{'voted': voted && votedSinceYesterday, 'show-vote-button': isLoggedIn && !votedSinceYesterday}" [class]="selectedClass">
                        <ng-container *ngFor="let option of orderedOptions; let i = index">
                            <div *ngIf="i % 2 == 0" class="livery-option" [ngClass]="['option-' + option.id, option.id === votedFor && votedSinceYesterday ? 'selected' : '']" (click)="openPreviewModal(i)">
                                <div class="image-container">
                                    <div *ngIf="option.id === votedFor && votedSinceYesterday" class="selected-checkmark">
                                        <i class="fa fa-check"></i>
                                    </div>
                                    <img [src]="option.imagePath" [alt]="option.alt">
                                    <button class="btn btn-link btn-expand">
                                        <i class="fa fa-expand"></i>
                                    </button>
                                </div>
                                <button class="btn btn-primary btn-vote" *ngIf="isLoggedIn && !votedSinceYesterday" (click)="recordVote($event, option.id)">
                                    Vote
                                </button>
                            </div>

                        </ng-container>
                    </div>





                    <div id="livery-rules">
                        <h3 class="text-primary">HOW TO VOTE</h3>
                        <ol>
                            <li>Log in – or sign up – to DodgeGarage.</li>
                            <li>Choose one of the three livery design options.</li>
                            <li>Return each day to cast another vote. Voting resets each day at midnight EST.</li>
                            <li>Voting will be open from 03/04/2025 through 06/02/2025.</li>
                        </ol>
                    </div>

                </section>
            </ng-container>
        </div>
    </div>
</div>

<!-- User Modal -->
<ng-template #userModal>
    <div class="modal-container">
        <div class="modal-header">
            <button class="modal-close" (click)="closeUserModal()"><i class="fa fa-close"><span class="sr-only">close</span></i></button>
        </div>
        <div class="modal-body site-wrapper">
            <div class="signup-form form-1320">
                <dg-user-onboarding class="step-form" (userLoggedIn)="closeUserModal()"></dg-user-onboarding>
            </div>
        </div>
    </div>
</ng-template>

<!-- User Modal -->
<ng-template #previewModal class="modal-livery-preview">
    <div class="modal-container">
        <div class="modal-header">
            <button class="modal-close" (click)="closePreviewModal()"><i class="fa fa-close"><span class="sr-only">close</span></i></button>
        </div>
        <div class="modal-body site-wrapper">
            <div class="swiper-parent">
                <swiper #swiper [config]="config" (swiper)="onSwiper($event)">
                    <ng-template swiperSlide *ngFor="let liveryOption of getOrderedLiveryOptions(); let i = index" >
                        <div class="slide-container">
                            <img [src]="liveryOption.imagePath" [alt]="liveryOption.alt">
                        </div>
                    </ng-template>
                </swiper>
                <div class="carousel-navigation">
                    <button id="carousel-next" class="carousel-button" type="button" aria-label="Carousel Slide Previous"><i class="fa fa-angle-right"></i></button>
                    <button id="carousel-prev" class="carousel-button" type="button" aria-label="Carousel Slide Next"><i class="fa fa-angle-left"></i></button>
                </div>
            </div>

        </div>
    </div>
</ng-template>