import { Component, Inject, OnInit, PLATFORM_ID, TemplateRef, ViewChild, AfterViewInit } from '@angular/core';
import {isPlatformBrowser, ViewportScroller} from '@angular/common';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UserService } from '../user/user.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import SwiperCore, { SwiperOptions, Virtual } from 'swiper';
import { liveryOptions } from './liveryoptions';
import { PollService } from "../polls/poll.service";
import { Poll } from "../polls/poll";
import { parseISO, isAfter, format, startOfToday} from 'date-fns';
import {SwiperComponent} from 'swiper/angular';

SwiperCore.use([Virtual]);

@Component({
  selector: 'dg-livery-contest',
  templateUrl: './livery-contest.component.html',
  styleUrls: ['./livery-contest.component.scss']
})
export class LiveryContestComponent implements OnInit, AfterViewInit {
  loggedInSub: Subscription;
  isLoggedIn: boolean = false;
  isLoggedIn$ = new BehaviorSubject<boolean>(this.isLoggedIn);
  isLoading: boolean = true;

  @ViewChild('userModal', { static: true }) public userModal: TemplateRef<any>;
  userModalRef: BsModalRef;
  isUserModalOpen: boolean = false;
  userModalHiddenSub: Subscription;

  @ViewChild('previewModal', { static: true }) public previewModal: TemplateRef<any>;
  previewModalRef: BsModalRef;
  isPreviewModalOpen: boolean = false;
  previewModalHiddenSub: Subscription;

  // meta
  sharingURL: string;
  shareImg: any;
  pageDescription: string;
  pageTitle: string;

  // swiper
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
  swiperInstance: any;
  config: SwiperOptions = {
    slidesPerView: 1,
    centeredSlides: true,
    loop: true,
    initialSlide: 0,
    navigation: {
      'nextEl': '#carousel-next',
      'prevEl': '#carousel-prev'
    },
    pagination: {
      el: '.carousel-indicators',
      clickable: true,
      type: 'bullets',
      bulletActiveClass: 'active',
      bulletClass: 'carousel-nav-item'
    }
  };

  liveryOptions: any[] = liveryOptions;
  orderedOptions: any[];

  // this will be false, set true for testing
  voted: boolean = false
  votedFor: number;
  votedWhen: string;
  votedSinceYesterday: boolean = false;
  pollId: number = 26; // adjust as needed
  poll: Poll = new Poll();



  constructor(
    public userService: UserService,
    private modalService: BsModalService,
    private pollService: PollService,
    private metaService: Meta,
    public title: Title,
    private router: Router,
    private viewportScroller: ViewportScroller,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {

    this.sharingURL = environment.url + this.router.url;
    this.shareImg = '/assets/images/logos/dodge-logo-large.png';
    this.pageTitle = "Dodge Motorsports Livery Shootout | DodgeGarage";
    this.pageDescription = "Choose your favorite livery design for the Dodge Mopar Motorsports dragsters racing in the US Nationals this fall.";
    title.setTitle( this.pageTitle );

    // Set Meta Data
    this.metaService.updateTag({ name: 'description', content: this.pageDescription });
    this.metaService.updateTag({ rel: 'canonical', href: environment.url + this.router.url });
    this.metaService.updateTag({ property: 'og:description', content: this.pageDescription });
    this.metaService.updateTag({ property: 'og:site_name', content: 'Dodge Garage' });
    this.metaService.updateTag({ property: 'og:title', content: this.pageTitle });
    this.metaService.updateTag({ property: 'og:type', content: 'article' });
    this.metaService.updateTag({ property: 'og:image', content: environment.url + this.shareImg });
    this.metaService.updateTag({ property: 'og:url', content: this.sharingURL });
    this.metaService.updateTag({ name: 'twitter:card', content: 'summary' });
    this.metaService.updateTag({ name: 'twitter:image', content: environment.url + this.shareImg });
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.loggedInSub = this.userService.loggedIn$.subscribe(
        loggedIn => {
          this.loggedInSub = this.userService.loggedIn$.subscribe(() => {
            this.isLoggedIn = this.userService.userModel.isLoggedIn;
            if (this.isLoggedIn) {
              this.pollService.getPoll(this.pollId).subscribe( data => {
                console.log("Getting poll data", data);
                this.poll = data;
                this.voted = this.poll.voted;
                if (this.poll.voted) {
                  this.votedFor = this.poll.lastVote.answerId;
                  this.votedWhen = this.poll.lastVote.lastVotedDate;
                  this.votedSinceYesterday = this.hasVotedSinceYesterday(this.poll.lastVote.lastVotedDate);
                }
                this.orderedOptions = this.getOrderedLiveryOptions();
                this.isLoading = false;
              });
            } else {
              this.orderedOptions = this.getOrderedLiveryOptions();
              this.isLoading = false;
            }
          });
        }
      );
    }
  }

  ngAfterViewInit(): void {
    console.log("voted:", this.voted);
    console.log("votedFor:", this.votedFor);
    console.log("votedWhen:", this.votedWhen);
    console.log("votedSinceYesterday:", this.votedSinceYesterday);
  }

  openUserModal(event) {
    event.preventDefault();
    if (!this.isUserModalOpen) {
      this.userModalRef = this.modalService.show(this.userModal, {class: 'modal-full'});
      this.isUserModalOpen = true;
    }
    this.userModalHiddenSub = this.modalService.onHidden.subscribe((reason: string) => {
      this.isUserModalOpen = false;
    });
  }

  closeUserModal() {
    if (this.userModalRef) {
      this.userModalRef.hide();
      this.isUserModalOpen = false;
    }
  }

  openPreviewModal(selection: number) {
    console.log("Open slideshow with slide # " + selection);
    event.preventDefault();
    if (!this.isPreviewModalOpen) {
      this.config.initialSlide = selection;
      this.previewModalRef = this.modalService.show(this.previewModal, { class: 'modal-full modal-preview' });
      this.isPreviewModalOpen = true;
    }
    this.previewModalHiddenSub = this.modalService.onHidden.subscribe((reason: string) => {
      this.isPreviewModalOpen = false;
    });
  }

  closePreviewModal() {
    if (this.previewModalRef) {
      this.previewModalRef.hide();
      this.isPreviewModalOpen = false;
    }
  }

  logout(event){
    event.preventDefault();
    this.userService.logout();
    this.isLoggedIn = false;
    this.isLoggedIn$.next(false);
  }

  recordVote($event: Event, selection: number) {
    $event.stopPropagation();
    console.log("Voting for option " + selection);
    this.pollService.submitVote(this.poll, selection, this.userService.userModel).subscribe(data => {
      console.log("Data:", data);
      if(data.voted && data.lastVote) {
        this.voted = true;
        this.votedFor = data.lastVote.answerId;
        this.votedWhen = data.lastVote.lastVotedDate;
        this.votedSinceYesterday = this.hasVotedSinceYesterday(data.lastVote.lastVotedDate);
        this.viewportScroller.scrollToPosition([0, 0]);
        console.log("User voted on " + format(parseISO(this.votedWhen), 'MMMM do, yyyy, h:mm a'));
      }
      console.log("User successfully voted for selection " + selection);
      this.orderedOptions = this.getOrderedLiveryOptions();
    })
  }

  getOrderedLiveryOptions(): any[] {
    const groups = [
      this.liveryOptions.slice(0, 2),
      this.liveryOptions.slice(2, 4),
      this.liveryOptions.slice(4, 6)
    ];

    console.log("voted:", this.voted);
    console.log("votedFor:", this.votedFor);
    console.log("votedWhen:", this.votedWhen);
    console.log("votedSinceYesterday:", this.votedSinceYesterday);

    if (this.voted && this.votedFor && this.votedSinceYesterday) {
      const votedGroup = groups.find(group => group[0].id === this.votedFor);
      const otherGroups = groups.filter(group => group[0].id !== this.votedFor);

      if (window.innerWidth < 1200) {
        // For screens less than 1200px, put the voted group first
        return [...votedGroup, ...otherGroups[0], ...otherGroups[1]];
      } else {
        // For screens 1200px or wider, keep the voted group in the middle
        return this.liveryOptions;
      }
    } else {
      return this.liveryOptions;
    }
  }

  hasVotedSinceYesterday(votedWhen: string): boolean {
    return isAfter(parseISO(votedWhen), startOfToday());
  }

  get selectedClass(): string {
    return this.votedFor ? `selected-${this.votedFor}` : '';
  }

  onSwiper(swiperInstance: any) {
    this.swiperInstance = swiperInstance;
  }

  ngOnDestroy() {
    if (this.loggedInSub)
      this.loggedInSub.unsubscribe();
  }

  protected readonly Math = Math;
}