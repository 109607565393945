import { Track } from './track';
import { HttpParams } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TrackLocatorService {

  constructor(
    private http: HttpClient
  ) { }

  getTracks(searchType: string, zipcode: string = null, trackName: string = null, city: string = null, state: string = null): Observable<any> {
    let url = environment.url + '/rest/track';
    let body = {
      searchType: searchType || null,
      postalCode: zipcode || null,
      trackName: trackName || null,
      city: city || null,
      state: state || null,
      countryCode: "US"
    };

    // Remove null values from payload
    Object.keys(body).forEach(key => (body[key] == null) && delete body[key]);

    return this.http.post<any>(url, body, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  mutateTracks(track: Track) {
    track.zipcode = track['postalCode'];
    if (!track.website.match(/^[a-zA-Z]+:\/\//) && track.website.length > 0)
    {
      track.website = 'http://' + track.website;
    }
    return track;
  }
}
