import { Subscription } from 'rxjs';
import { BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { Component, Inject, PLATFORM_ID, ViewChild, OnInit, AfterContentChecked } from '@angular/core';
import { isPlatformBrowser, Location, LocationStrategy, ViewportScroller } from '@angular/common';
import { UserService } from './user/user.service';
import { Router, NavigationEnd, ActivatedRoute, NavigationStart, Scroll } from "@angular/router";
import { NewsArticleService } from './news-articles/news-article.service';
import { NewsArticle } from './news-articles/news-article';
import { Event } from "./event/event";
import { EventService} from './event/event.service';
import { Title, Meta } from '@angular/platform-browser';
import { filter, delay } from 'rxjs/operators';
import { NewsArticleSinglePageComponent } from './news-articles/news-article-single-page/news-article-single-page.component';
import { EventSinglePageComponent } from './event/event-single-page/event-single-page.component';
import { AddThisService } from './services/addthis.service';
import { environment } from '../environments/environment';

declare let ga:any

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {

  constructor(
    private location: Location,
    public newsArticleService: NewsArticleService,
    public eventService: EventService,
    private route: ActivatedRoute,
    private userService: UserService,
    public title: Title,
    public metaService: Meta,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    private locStrat: LocationStrategy,
    private viewportScroller: ViewportScroller,
    private addThisService: AddThisService
  ) {
    if (!environment.production) {
      this.metaService.addTag({ name: 'robots', content: 'noindex, nofollow' });
    }
    
    this.router.events.pipe(
			filter((e: any): e is Scroll => e instanceof Scroll),
			delay(0),
		).subscribe((event) => {
      if (event.position) {
				viewportScroller.scrollToPosition(event.position);
			} else if (event.anchor) {
				viewportScroller.scrollToAnchor(event.anchor);
			} else {
				viewportScroller.scrollToPosition([0, 0]);
      }
    });

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      // Check the URL to decide whether to show the navigation and footer
      this.showNavAndFooter = !event.urlAfterRedirects.startsWith('/eu');
    });
  }

  @ViewChild(ModalDirective, { static: true }) public newsModal: ModalDirective;
  @ViewChild('eventModal', { static: false }) public eventModal: ModalDirective;
  newsModalRef: BsModalRef;
  eventModalRef: BsModalRef;
  selectedArticle: NewsArticle = new NewsArticle();
  selectedEvent: Event = new Event();
  newsModalOpenSub: Subscription;
  eventModalOpenSub: Subscription;
  previousURL: string = '';
  isUpdateURL: boolean = false;
  currentPageTitle: string = this.title.getTitle();
  showNavAndFooter: boolean = true;

  @ViewChild(NewsArticleSinglePageComponent)
  private newsArticleSinglePageComponent: NewsArticleSinglePageComponent;

  @ViewChild(EventSinglePageComponent)
  private eventSinglePageComponent: EventSinglePageComponent;

  private _isPopState = false;
  private _routeScrollPositions: { [url: string]: number } = {};
  private _deferredRestore = false;

  ngOnInit() {

    this.newsModalOpenSub = this.newsArticleService.isNewsModalOpen$.subscribe(modalOpen => {
      if (modalOpen) {
        this.openNewsModal();
      }
    });

    this.eventModalOpenSub = this.eventService.isEventModalOpen$.subscribe(modalOpen => {
      if (modalOpen) {
        this.openEventModal(this.eventService.eventOpen);
      }
    });

    if (isPlatformBrowser(this.platformId)) {
      this.userService.isLoggedIn();

      this.location.subscribe( data => {
        if (!data.url.includes('/news/article') && data.type == 'popstate' && this.newsArticleService.isNewsModalOpen) {
          this.closeNewsModal();
        }
      });

      // Detect WebP Support
      var webp = new Image();
      webp.onerror = function(){
        document.body.classList.add('no-webp');
      };
      webp.onload = function(){
        document.body.classList.add('webp');
      };
      webp.src = 'data:image/webp;base64,UklGRjIAAABXRUJQVlA4ICYAAACyAgCdASoBAAEALmk0mk0iIiIiIgBoSygABc6zbAAA/v56QAAAAA==';
    }

    this.router.events.subscribe((event) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }

      // Remove AddThis
      if (isPlatformBrowser(this.platformId)) {
        this.removeAddThis();
      }
      // Reset any messages
      this.userService.clearMessages();

      // Close the news modal if needed
      if (!event.url.includes('/news/article') && this.newsArticleService.isNewsModalOpen) {
        this.closeNewsModal(false);
      }

      // Hide AddThis. We'll need to unhide it on individual pages.
      this.addThisService.setAddThisVisibility(false);

      // Send pageview to GA
      // If we want to remove URL parameters, use event.urlAfterRedirects.split('?')[0]
      // let consentArray = [];
      // let consent: any = this.getCookie('OptanonConsent');
      // consent = consent.split('&');
      // console.log(consent);

      // consent.forEach(item => {
      //   consentArray.push({
      //     'name': item.substring(0, item.indexOf('=')),
      //     'value': item.substring(item.indexOf('=') + 1)
      //   })
      // });

      // console.log('Consent Obj ', consentArray);
      if (isPlatformBrowser(this.platformId) && ga != undefined) {
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');
      }
    });

  }

  getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  removeAddThis() {
    const script = document.createElement('script');
    script.src ='//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5a00af0345615a0f';
    const scripts = document.getElementsByTagName('script');
    for (let i = scripts.length; i--;) {
      if (scripts[i].src == script.src) {
        // Remove script if not the proper url
        scripts[i].parentNode.removeChild(scripts[i]);
        return true;
      }
    }
    return false;
  }

  openNewsModal() {
    this.previousURL = this.router.url;
    this.currentPageTitle = this.title.getTitle();
    this.location.go('/news/article/'+this.newsArticleService.newsArticleOpen.cleanLink);
    this.newsModal.show();
    this.addThisService.setAddThisVisibility(true);

    // Capture Pageview for GA
    ga('send', {
      hitType: 'pageview',
      page: this.location.path(),
      title: this.newsArticleService.newsArticleOpen['title']
    });
  }

  closeNewsModal(isUpdateURL: boolean = true) {
    // Add any magic to fire when closing the modal
    this.newsArticleService.setModalOpen(false);
    this.newsModal.hide();
    this.newsArticleService.newsArticleOpen = new NewsArticle();
    this.isUpdateURL = isUpdateURL;
    if (isUpdateURL)
      this.location.replaceState(this.previousURL);
    this.addThisService.setAddThisVisibility(false);

    // Force page title back to default page title after modal closing.
    this.title.setTitle(this.currentPageTitle);
  }

  newsModalListener(type: string, $event: ModalDirective) {
    // Catch closing on backdrop click only
    if (type == 'onHidden' && $event.dismissReason == 'backdrop-click') {
      this.closeNewsModal(true);
    }
  }

  likeArticleEmitter() {
    this.newsArticleSinglePageComponent.likeArticle();
  }

  removeLikeArticleEmitter() {
    this.newsArticleSinglePageComponent.removeLikeArticle();
  }

  newsArticleOpenUpdated($event) {
    this.newsArticleService.newsArticleOpen = $event;
  }

  openEventModal(event: Event) {
    this.previousURL = this.router.url;
    this.currentPageTitle = this.title.getTitle();
    this.location.go(this.eventService.getEventPath(event));
    this.eventModal.show();
    this.addThisService.setAddThisVisibility(false);
    this.eventService.eventOpen = event;

    // Capture Pageview for GA
    ga('send', {
      hitType: 'pageview',
      page: this.location.path(),
      title: this.eventService.eventOpen['title']
    });
  }

  closeEventModal(isUpdateURL: boolean = true) {
    // Add any magic to fire when closing the modal
    this.eventService.setModalOpen(false);
    this.eventModal.hide();
    this.eventService.eventOpen = new Event();
    this.isUpdateURL = isUpdateURL;
    if (isUpdateURL)
      this.location.replaceState(this.previousURL);
    this.addThisService.setAddThisVisibility(false);

    // Force page title back to default page title after modal closing.
    this.title.setTitle(this.currentPageTitle);
  }

  eventModalListener(type: string, $event: ModalDirective) {
    // Catch closing on backdrop click only
    if (type == 'onHidden' && $event.dismissReason == 'backdrop-click') {
      this.closeEventModal(true);
    }
  }

  eventOpenUpdated($event) {
    this.eventService.eventOpen = $event;
  }


}
