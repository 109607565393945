export const liveryOptions: Array<any> = [
  {
    id: 106,
    imagePath: "/assets/images/livery-shootout/livery-option-1-1.webp",
    alt: "Livery Option 1"
  },
  {
    id: 106,
    imagePath: "/assets/images/livery-shootout/livery-option-1-2.webp",
    alt: "Livery Option 1"
  },
  {
    id: 107,
    imagePath: "/assets/images/livery-shootout/livery-option-2-1.webp",
    alt: "Livery Option 2"
  },
  {
    id: 107,
    imagePath: "/assets/images/livery-shootout/livery-option-2-2.webp",
    alt: "Livery Option 2"
  },
  {
    id: 108,
    imagePath: "/assets/images/livery-shootout/livery-option-3-1.webp",
    alt: "Livery Option 3"
  },
  {
    id: 108,
    imagePath: "/assets/images/livery-shootout/livery-option-3-2.webp",
    alt: "Livery Option 3"
  }
]