<div id="livery-contest-tile">

    <div class="copy">
        <h2>
            DODGE MOTORSPORTS<br>LIVERY SHOOTOUT
        </h2>
        <p>We’ve got three Dodge Top Fuel and Funny Car livery designs for the US Nationals and we just can’t make up our minds. Help us pick a winner.</p>
        <a href="/livery-shootout" (click)="sendGTMEvent()" class="btn btn-primary">
            Vote Now
        </a>
    </div>
</div>
