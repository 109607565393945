import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
//import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Poll} from "./poll";
//import {Answer} from "./answer";
import {UserModel} from "../user/user";
import {environment} from '../../environments/environment';

@Injectable()
export class PollService {

  constructor(
    private http: HttpClient
  ) { }

  getPoll(id: number): Observable<any> {
    let url = environment.url + `/rest/poll`; // Adjust API endpoint if needed
    let body = { pollId: id }; // Sending as JSON

    return this.http.post<any>(url, body, {
      headers: { 'Content-Type': 'application/json' }
    });
  }


  submitVote(poll: Poll, answerId: number, user: UserModel): Observable<any> {
    let url = environment.url + `/rest/poll/${poll.id}/vote`;
    // Construct JSON payload
    let body = {
      answerId: answerId
    };

    // Send JSON request
    return this.http.post<any>(url, body, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

}
