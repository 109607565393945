import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dg-livery-contest-tile',
  templateUrl: './livery-contest-tile.component.html',
  styleUrls: ['./livery-contest-tile.component.scss']
})
export class LiveryContestTileComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  sendGTMEvent(): void {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'livery-contest.homepage-banner.click',
      eventCategory: 'Hero',
      eventAction: 'Click',
      eventLabel: 'Livery Contest Homepage Hero Banner'
    });
  }

}
