import {Answer} from "./answer";

export class Poll {
  id: number;
  question: string;
  totalVotes: number;
  answers: Answer[] = [];
  results: Array<any> = [];
  voted: boolean = false;
  lastVote: null | {
    answerId: number;
    lastVotedDate: string;
  };
}
